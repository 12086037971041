<template>
  <div id="story-mode-ownership">
    <!-- Main Navigation -->
    <main-navigation />
    <!-- /Main Navigation -->

    <!-- Container -->
    <div class="container-1200">

      <!-- Content -->
      <div class="content">
        <v-app>
          <!-- Page Header -->
          <header class="page-header">

            <!-- Dashboard Header -->
            <dashboard-header
              :class="{'stamp': isOwnershipCompleted}"
              :title="$t('pages.businessGuide.ownership.ownership')"
              :completed-step-name="completedStepName"
              hide-quick-tour-button
              :guide-visible="guideVisible"
              :guide-visible-first-time="guideVisibleFirstTime"
              @toggle-guide="guideVisible = !guideVisible"
              @open-info="openInfo"
              @open-ai-buddy-drawer="aiBuddyDrawerVisible = true"
            />
            <!-- /Dashboard Header -->

            <!-- Divider -->
            <hr>
            <!-- /Divider -->

          </header>
          <!-- /Page Header -->

          <!-- Info Guide Expand Panel -->
          <info-guide
            v-if="guideVisibleFirstTime"
            class="mb-5"
            type="ownershipExamples"
            visible
            @click="guideVisibleFirstTime = !guideVisibleFirstTime"
          />
          <!-- Info Guide Expand Panel -->

          <!-- Ai Buddy Drawer -->
          <ai-buddy-drawer
            :visible="aiBuddyDrawerVisible"
            section-name="Ownership"
            help-me-write
            type="ownership"
            @close="aiBuddyDrawerVisible = false"
          />
        </v-app>
        <!-- /Ai Buddy Drawer -->

        <!-- Business plan additional info -->
        <business-plan-additional-info
          :completed-step-name="completedStepName"
          section-name="Ownership"
          show-ai-buddy
          @ai-buddy-open="aiBuddyDrawerVisible = true"
        />
        <!-- /Business plan additional info -->

        <!-- Height Holder For Sticky Header -->
        <div class="height-holder d-none d-md-block" />
        <!-- /Height Holder For Sticky Header -->

        <div class="ownership-wrapper position-relative mb-5">
          <!-- Header -->
          <business-guide-header class="position-relative mb-md-0 mt-4 mt-md-0">
            <!-- Completed Overlay -->
            <completed-overlay
              v-if="showOverlay"
              @click="checkOwnership"
            />
            <!-- /Completed Overlay -->
            <template #left>
              <p class="text-lead xl m-0 mb-md-3 mt-md-4 o-100">
                {{ $t('pages.businessGuide.ownership.businessStructure') }}
              </p>
            </template>
            <template #right>
              <erase-icon-button
                v-if="removeButtonVisible"
                @click="dialogRemoveExamplesVisible = true"
              />
            </template>
          </business-guide-header>
          <!-- /Header -->

          <el-row :gutter="20">
            <el-col class="position-relative" :md="12" :lg="14">
              <!-- Completed Overlay -->
              <completed-overlay
                v-if="showOverlay"
                @click="checkOwnership"
              />
              <!-- /Completed Overlay -->
              <types-form />
            </el-col>
          </el-row>

          <p class="text-lead xl m-0 mb-3 my-md-3 o-100">
            {{ $t('pages.businessGuide.ownership.ownersEquity') }}
          </p>

          <el-row :gutter="20" class="d-flex flex-wrap flex-column-reverse flex-md-row mb-3">
            <el-col :md="12" :lg="14" class="d-flex flex-column position-relative mb-3 mb-md-0">
              <!-- Completed Overlay -->
              <completed-overlay
                v-if="showOverlay"
                @click="checkOwnership"
              />
              <!-- /Completed Overlay -->
              <owners-list-card
                v-if="ownersLength"
                class="flex-grow-1"
                @edit="dialogAddOwnersVisible = true"
              />
              <add-new-card
                v-else
                :disabled="hasNotAccess"
                class="py-2"
                :title="$t('pages.businessGuide.ownership.addOwners')"
                horizontal
                @click="dialogAddOwnersVisible = true"
              />
            </el-col>
            <el-col :md="12" :lg="10" class="mb-4 mb-md-0">
              <share-card />
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- /Content -->

      <!-- Add More Details -->
      <add-more-details
        category="setUp"
        step="ownership"
        concept-name="ownershipMoreDetails"
        completed-step-name="ownershipCompleted"
      />
      <!-- /Add More Details -->
    </div>
    <!-- /Container -->

    <!-- Main Bottom Bar -->
    <main-bottom-bar
      :back="backNavigation"
      :next="nextNavigation"
      :step-filled="isStepFilled"
      :completed-step-name="completedStepName"
      @complete-step="onCompleteStep"
    />
    <!-- /Main Bottom Bar -->

    <!-- Info Guide Drawer -->
    <info-guide-drawer
      type="ownershipExamples"
      :visible="guideVisible"
      @close="guideVisible = false"
    />
    <!-- /Info Guide Drawer -->

    <!-- Dialog Add Owners -->
    <dialog-add-owners
      :visible="dialogAddOwnersVisible"
      @close="dialogAddOwnersVisible = false"
    />
    <!-- Dialog Add Owners -->

    <!-- Dialog Process Completed -->
    <dialog-process-completed
      :dialog-process-completed-visible="dialogProcessCompletedVisible"
      @close-dialog-process-completed="onCloseDialogProcessCompleted"
    />
    <!-- /Dialog Process Completed -->

    <!-- Dialog Remove Examples -->
    <dialog-remove-examples
      :visible="dialogRemoveExamplesVisible"
      :loading="removeExamplesLoading"
      @no="dialogRemoveExamplesVisible = false"
      @yes="deleteExamples"
    />
    <!-- /Dialog Remove Examples -->
  </div>
</template>

<script>
import AddMoreDetails from '@/views/Home/StoryMode/Components/AddMoreDetails'
import AddNewCard from '@/components/_v2/AddNewCard'
import AiBuddyDrawer from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddyDrawer.vue'
import BusinessGuideHeader from '@/components/_v2/Common/BusinessGuideHeader'
import BusinessPlanAdditionalInfo from '@/views/Home/StoryMode/Components/BusinessPlanAdditionalInfo.vue'
import CompletedOverlay from '@/views/Home/StoryMode/Components/CompletedOverlay'
import DashboardHeader from '@/views/Home/StoryMode/Components/DashboardHeader'
import DialogAddOwners from '@/views/Home/StoryMode/SetUp/Ownership/Components/Dialogs/DialogAddOwners'
import DialogProcessCompleted from '@/views/Home/StoryMode/Components/Dialogs/DialogProcessCompleted'
import DialogRemoveExamples from '@/views/Home/StoryMode/Components/DialogRemoveExamples'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import MainBottomBar from '@/views/Home/StoryMode/Components/MainBottomBar/MainBottomBar'
import MainNavigation from '@/views/Home/StoryMode/Components/MainNavigation/MainNavigation'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinProcessCompleted from '@/mixins/processCompleted'
import OwnersListCard from '@/views/Home/StoryMode/SetUp/Ownership/Components/OwnersList/OwnersListCard'
import ShareCard from '@/views/Home/StoryMode/SetUp/Ownership/Components/ShareCard'
import TypesForm from '@/views/Home/StoryMode/SetUp/Ownership/Components/TypesForm'

import { mapActions, mapGetters } from 'vuex'
import InfoGuide from '@/views/Home/StoryMode/Components/Example/InfoGuide.vue'
import InfoGuideDrawer from '@/views/Home/StoryMode/Components/Example/InfoGuideDrawer.vue'

export default {
  name: 'Ownership',

  components: {
    InfoGuide,
    AiBuddyDrawer,
    InfoGuideDrawer,
    BusinessPlanAdditionalInfo,
    AddMoreDetails,
    AddNewCard,
    BusinessGuideHeader,
    CompletedOverlay,
    DashboardHeader,
    DialogAddOwners,
    DialogProcessCompleted,
    DialogRemoveExamples,
    EraseIconButton,
    MainBottomBar,
    MainNavigation,
    OwnersListCard,
    ShareCard,
    TypesForm
  },

  mixins: [MixinDeleteIdeaExamples, MixinGuide, MixinIdeaRoles, MixinProcessCompleted],

  data () {
    return {
      aiBuddyDrawerVisible: false,
      guideVisible: false,
      dialogAddOwnersVisible: false,
      dialogRemoveExamplesVisible: false,
      removeExamplesLoading: false,
      completedStepName: 'ownershipCompleted'
    }
  },

  computed: {
    ...mapGetters('idea', ['getStepIsCompleted', 'isIdeaFromTemplate']),
    ...mapGetters('idea/ownership', ['ownership', 'getOwners']),

    isOwnershipCompleted () {
      return this.getStepIsCompleted(this.completedStepName)
    },

    ownersLength () {
      return this.getOwners.length
    },

    isStepFilled () {
      return this.ownersLength > 0 && this.ownership.type !== null
    },

    isOwnershipFromTemplate () {
      return !this.ownership.isTouched && this.ownership.isFromTemplate
    },

    isOwnersFromTemplate () {
      return this.getOwners.every(owner => !owner.isTouched && owner.isFromTemplate)
    },

    hasItemsFromTemplate () {
      return this.isOwnershipFromTemplate && this.isOwnersFromTemplate
    },

    hasNotAccess () {
      return !this.canEdit || this.isOwnershipCompleted
    },

    showOverlay () {
      return this.canEdit && this.isOwnershipCompleted
    },

    removeButtonVisible () {
      return [this.hasItemsFromTemplate, this.canEdit].every(value => value)
    },

    backNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.staffAndPayroll'),
        route: 'business-guide-set-up-staff-and-payroll'
      }
    },

    nextNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.revenueStreams'),
        route: 'business-guide-projections-revenue-streams'
      }
    }
  },

  created () {
    this.openGuide('ownershipGuide')
  },

  methods: {
    ...mapActions('idea', ['deleteIdeaExamples']),

    async deleteExamples () {
      try {
        this.removeExamplesLoading = true
        const response = await this.deleteIdeaExamples('ownership')

        const { type, ids } = response
        this.deleteFromState({ type, ids })
        this.removeExamplesLoading = false
        this.dialogRemoveExamplesVisible = false
      } catch (error) {
        this.removeExamplesLoading = true
      }
    },

    checkOwnership () {
      if (!this.canEdit) return
      if (this.isOwnershipCompleted) {
        this.dialogProcessCompletedVisible = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
#story-mode-ownership {
  min-height: 100vh;
  margin-bottom: 100px;

  @include media-breakpoint-up($md) {
    margin-bottom: 50px;
  }

  .content {
    padding-top: 12px;

    .guide-example-wrapper {
      margin-bottom: 20px;
    }

    @include media-breakpoint-up($md) {
      padding-top: 32px;
    }

    .text-lead {
      opacity: 0.8;
      font-size: 24px;
    }
  }
}
</style>
